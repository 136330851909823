.button {
    appearance: none;
    display: inline-block;
    /*display: flex;
    flex-direction: column;
    align-items: center;*/
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: normal;
    margin: 0;
    padding: var(--half-padding) var(--double-padding);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    background-color: var(--color-alloy);
    border: 2px solid var(--color-alloy);
    border-radius: var(--base-border-radius);
    transition: all 0.2s ease-in;

    &:hover {
        box-shadow: inset 0px 50rem 0 0 rgba(0, 0, 0, 0.2);
        text-decoration: none;
        outline: none;
        color: white;
    }

    &.disabled,
    &:disabled {
        background-color: var(--color-cadet);
        border-color: var(--color-cadet);

        &:hover {
            cursor: no-drop;
        }
    }

    &--inverted {
        background-color: transparent;
        border: 2px solid var(--color-alloy);
        color: var(--font-color);

        &:hover {
            background-color: white;
            border: 2px solid white;
            color: var(--font-color);
        }
    }

    &--large {
        font-size: 1.25rem;
        padding: 1rem 1.5rem;
    }

    &--note {
        font-size: 0.75rem;
        font-weight: normal;
        line-height: 1rem;
    }

    // Colours

    @each $colour in $colours {
        &--#{$colour} {
            background-color: var(--color-#{$colour});
            border-color: var(--color-#{$colour});
            color: var(--color-white);

            &:hover {
                border-color: var(--color-#{$colour});
            }

            &.button--inverted {
                background-color: transparent;
                border-color: var(--color-#{$colour});
                color: var(--color-#{$colour});

                &:hover {
                    background-color: var(--color-#{$colour});
                    border-color: var(--color-#{$colour});
                    color: var(--color-white);
                }
            }
        }
    }
}

.link-button {
    color: var(--color-island);
    text-decoration: underline;
}
