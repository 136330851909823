h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: var(--base-padding);
    line-height: 1.5;
}

h1 {
    font-size: 1.25rem;
}
h2 {
    font-size: 1.125rem;
}
h3 {
    font-size: 1;
}
h4,
h5,
h6 {
    font-size: 1.25rem;
}

a {
    color: var(--color-island);
}

ul.bullet-list {
    margin-left: var(--double-padding);
    margin-bottom: 0;

    @include breakpoint('md') {
        margin-bottom: var(--base-padding);
    }

    li {
        padding-bottom: var(--half-padding);
    }
}

ul.stat-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;

    @include breakpoint('md') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    li {
        display: flex;
        align-items: center; 
        flex-direction: column;
        padding: var(--base-padding);
        text-align: center;
        font-size: 1rem;

        @include breakpoint('md') {
            margin-right: var(--base-padding);
            font-size: 20px;
        }
    }

    span {
        color: #f1672a;
        margin-right: .5rem;
        font-size: 2rem;
        font-weight: 900;
        font-family: 'Exo', sans-serif;

        @include breakpoint('md') {
            font-size: 7rem;
        }
    }
}

.center-text {
    text-align: center;
}

.section-title {
    @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;0,600;1,100;1,400;1,600&display=swap');
    font-family: 'Exo', sans-serif;
    font-weight: 600;
}

.filters {
    display: none;
    list-style: none;

    a {
        text-decoration: none;
    }
}

.filter {
    position: relative;
    min-width: auto;
    width: 100%;
    
    @include breakpoint('md') {
        position: absolute;
        right: var(--base-padding);
        bottom: var(--base-padding);
        min-width: 20rem;
        width: auto;
    }

    input {
        width: 100%;
        padding: 1rem;
    }
}