.card {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);

    &--large {
        grid-column-end: span 2;
    }

    &--recommendation {
        position: relative;
        flex-direction: column;
        justify-content: start;
        background-color: white;
        padding: var(--base-padding);
        border-radius: 0 0 0 4rem;
        filter: none;

        .card__profile {
            display: flex;
            width: 100%;
            margin-top: auto;
        }

        .card__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: 'Exo', sans-serif;
        }

        .card__name {
            font-weight: 600;
            margin-bottom: .5rem;
        }

        .card__title, .card__company {
            font-size: 0.875rem;
            
            @include breakpoint('md') {
                font-size: 1rem;
            }
        }

        .card__image {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            margin-right: var(--base-padding);
            overflow: hidden;

            img {
                width: 5rem;
                height: 5rem;
                object-fit: cover;
            }
        }

        .card__recommendation {
            font-style: italic;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-bottom: var(--base-padding);

            @include breakpoint('md') {
                font-size: 1.125rem;
            }
        }
    }

    &--website {
        filter: none;
        position: relative;
        height: 15rem;
        justify-content: start;
        align-items: flex-start;
        overflow: hidden;

        @include breakpoint('md') {
            height: 24rem;
        }

        &:hover {
            &::before {
                opacity: .5;
                transition: opacity .3s ease;
            }
            .website__content {
                display: flex;
                transition: all .3s ease;
            }
            img {
                transform: scale(1.1);
                transition: transform 4s ease-out 0s;
            }
        }

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0;
            transition: opacity .3s ease;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        .website__content {
            display: none;
            flex-direction: column;
            padding: 1rem;
            position: absolute;
            z-index: 1;
            transition: all .3s ease;

            @include breakpoint('md') {
                padding: var(--double-padding);
                top: var(--base-padding);
                left: var(--base-padding);
            }
        }

        .website__name {
            font-size: 1rem;
            color: white;
            margin-bottom: var(--base-padding);
            background-color: #3d3d3d;
            width: fit-content;
            padding: .5rem;
            border-radius: 0 0 0 10px;
        }

        .website__summary {
            display: none;
            font-size: 2rem;
            color: white;

            @include breakpoint('md') {
                display: block;
            }
        }

        .website__tag {
            position: absolute;
            z-index: 1;
            bottom: .75rem;
            right: .75rem;
            border: 1px solid white;
            border-radius: 50%;
            padding: .5rem;

            @include breakpoint('md') {
                bottom: var(--base-padding);
                right: var(--base-padding);
            }

            svg {
                fill: #fff;
            }
        }

        .website__url {
            position: absolute;
            bottom: .75rem;
            right: 3.5rem;
            padding: .5rem;

            @include breakpoint('md') {
                bottom: var(--base-padding);
                right: 4rem;
            }
        }
    }


    &--service {
        filter: none;
        flex-direction: column;
        background-color: white;
        padding: var(--base-padding);

        .card__icon {
            height: 10rem;
            width: 10rem;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .card__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: 'Exo', sans-serif;
        }

        .card__name {
            margin-bottom: var(--base-padding);
        }

        .card__summary {
            font-size: .875rem;
        }
    }
}

.certificate {
    display: flex;
    flex-direction: column;

    img {
        height: 4rem;
        width: 10rem;
        margin-bottom: 1rem;
        margin-right: auto;
    }

    p {
        margin-bottom: 0;
    }
}