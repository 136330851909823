@import url(https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;0,600;1,100;1,400;1,600&display=swap);

:root {
  --grid-gutter: 1.25rem;
  --half-padding: 0.625rem;
  --base-padding: 1.25rem;
  --double-padding: 2.5rem;
  --triple-padding: 3.75rem;
  --quadriple-padding: 5rem;
  --base-border-radius: 0.375rem;
  --font-color: var(--color-type);
  --font-base: 'Rajdhani',sans-serif;
  --color-white: white;
  --color-orange: #f1672a;
  --color-darkgrey: #20221D;
  --color-type: #20221D;
  --color-facebook: #3b5998;
  --color-instagram: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
  --color-twitter: #1da1f2;
  --color-linkedin: #0077b5;
  --color-whatsapp: #25d366; }

body::before {
  content: 'xs';
  display: none;
  visibility: hidden; }
  @media (min-width: 480px) {
    body::before {
      content: 'sm'; } }
  @media (min-width: 768px) {
    body::before {
      content: 'md'; } }
  @media (min-width: 60em) {
    body::before {
      content: 'lg'; } }
  @media (min-width: 80em) {
    body::before {
      content: 'xl'; } }

.coverlink {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer; }

.hiddentext {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent; }

.text-center {
  text-align: center; }

.font-size-17 {
  font-size: 17px; }

.font-size-23 {
  font-size: 23px; }

.angle-top {
  content: " ";
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid #fff; }

.angle-top {
  color: #fff;
  border-right-width: 100%;
  border-top: 80px solid transparent;
  top: -80px; }

.angle-bottom {
  content: " ";
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #fff; }

.angle-bottom {
  color: #fff;
  border-left-width: 100%;
  border-bottom: 80px solid transparent;
  bottom: -80px; }

.counter-top-skew {
  padding-top: 160px !important; }

.key svg {
  margin: 1rem; }
  .key svg:first-child {
    margin-left: 0; }

.button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: inline-block;
  /*display: flex;
    flex-direction: column;
    align-items: center;*/
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: normal;
  margin: 0;
  padding: 0.625rem 2.5rem;
  padding: var(--half-padding) var(--double-padding);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  background-color: var(--color-alloy);
  border: 2px solid var(--color-alloy);
  border-radius: 0.375rem;
  border-radius: var(--base-border-radius);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }
  .button:hover {
    box-shadow: inset 0px 50rem 0 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
    outline: none;
    color: white; }
  .button.disabled, .button:disabled {
    background-color: var(--color-cadet);
    border-color: var(--color-cadet); }
    .button.disabled:hover, .button:disabled:hover {
      cursor: no-drop; }
  .button--inverted {
    background-color: transparent;
    border: 2px solid var(--color-alloy);
    color: #20221D;
    color: var(--font-color); }
    .button--inverted:hover {
      background-color: white;
      border: 2px solid white;
      color: #20221D;
      color: var(--font-color); }
  .button--large {
    font-size: 1.25rem;
    padding: 1rem 1.5rem; }
  .button--note {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1rem; }
  .button--white {
    background-color: white;
    background-color: var(--color-white);
    border-color: white;
    border-color: var(--color-white);
    color: white;
    color: var(--color-white); }
    .button--white:hover {
      border-color: white;
      border-color: var(--color-white); }
    .button--white.button--inverted {
      background-color: transparent;
      border-color: white;
      border-color: var(--color-white);
      color: white;
      color: var(--color-white); }
      .button--white.button--inverted:hover {
        background-color: white;
        background-color: var(--color-white);
        border-color: white;
        border-color: var(--color-white);
        color: white;
        color: var(--color-white); }
  .button--darkgrey {
    background-color: #20221D;
    background-color: var(--color-darkgrey);
    border-color: #20221D;
    border-color: var(--color-darkgrey);
    color: white;
    color: var(--color-white); }
    .button--darkgrey:hover {
      border-color: #20221D;
      border-color: var(--color-darkgrey); }
    .button--darkgrey.button--inverted {
      background-color: transparent;
      border-color: #20221D;
      border-color: var(--color-darkgrey);
      color: #20221D;
      color: var(--color-darkgrey); }
      .button--darkgrey.button--inverted:hover {
        background-color: #20221D;
        background-color: var(--color-darkgrey);
        border-color: #20221D;
        border-color: var(--color-darkgrey);
        color: white;
        color: var(--color-white); }
  .button--island {
    background-color: var(--color-island);
    border-color: var(--color-island);
    color: white;
    color: var(--color-white); }
    .button--island:hover {
      border-color: var(--color-island); }
    .button--island.button--inverted {
      background-color: transparent;
      border-color: var(--color-island);
      color: var(--color-island); }
      .button--island.button--inverted:hover {
        background-color: var(--color-island);
        border-color: var(--color-island);
        color: white;
        color: var(--color-white); }
  .button--cobalt {
    background-color: var(--color-cobalt);
    border-color: var(--color-cobalt);
    color: white;
    color: var(--color-white); }
    .button--cobalt:hover {
      border-color: var(--color-cobalt); }
    .button--cobalt.button--inverted {
      background-color: transparent;
      border-color: var(--color-cobalt);
      color: var(--color-cobalt); }
      .button--cobalt.button--inverted:hover {
        background-color: var(--color-cobalt);
        border-color: var(--color-cobalt);
        color: white;
        color: var(--color-white); }
  .button--zinc {
    background-color: var(--color-zinc);
    border-color: var(--color-zinc);
    color: white;
    color: var(--color-white); }
    .button--zinc:hover {
      border-color: var(--color-zinc); }
    .button--zinc.button--inverted {
      background-color: transparent;
      border-color: var(--color-zinc);
      color: var(--color-zinc); }
      .button--zinc.button--inverted:hover {
        background-color: var(--color-zinc);
        border-color: var(--color-zinc);
        color: white;
        color: var(--color-white); }
  .button--alloy {
    background-color: var(--color-alloy);
    border-color: var(--color-alloy);
    color: white;
    color: var(--color-white); }
    .button--alloy:hover {
      border-color: var(--color-alloy); }
    .button--alloy.button--inverted {
      background-color: transparent;
      border-color: var(--color-alloy);
      color: var(--color-alloy); }
      .button--alloy.button--inverted:hover {
        background-color: var(--color-alloy);
        border-color: var(--color-alloy);
        color: white;
        color: var(--color-white); }
  .button--pigeon {
    background-color: var(--color-pigeon);
    border-color: var(--color-pigeon);
    color: white;
    color: var(--color-white); }
    .button--pigeon:hover {
      border-color: var(--color-pigeon); }
    .button--pigeon.button--inverted {
      background-color: transparent;
      border-color: var(--color-pigeon);
      color: var(--color-pigeon); }
      .button--pigeon.button--inverted:hover {
        background-color: var(--color-pigeon);
        border-color: var(--color-pigeon);
        color: white;
        color: var(--color-white); }
  .button--cadet {
    background-color: var(--color-cadet);
    border-color: var(--color-cadet);
    color: white;
    color: var(--color-white); }
    .button--cadet:hover {
      border-color: var(--color-cadet); }
    .button--cadet.button--inverted {
      background-color: transparent;
      border-color: var(--color-cadet);
      color: var(--color-cadet); }
      .button--cadet.button--inverted:hover {
        background-color: var(--color-cadet);
        border-color: var(--color-cadet);
        color: white;
        color: var(--color-white); }
  .button--red {
    background-color: var(--color-red);
    border-color: var(--color-red);
    color: white;
    color: var(--color-white); }
    .button--red:hover {
      border-color: var(--color-red); }
    .button--red.button--inverted {
      background-color: transparent;
      border-color: var(--color-red);
      color: var(--color-red); }
      .button--red.button--inverted:hover {
        background-color: var(--color-red);
        border-color: var(--color-red);
        color: white;
        color: var(--color-white); }
  .button--maroon {
    background-color: var(--color-maroon);
    border-color: var(--color-maroon);
    color: white;
    color: var(--color-white); }
    .button--maroon:hover {
      border-color: var(--color-maroon); }
    .button--maroon.button--inverted {
      background-color: transparent;
      border-color: var(--color-maroon);
      color: var(--color-maroon); }
      .button--maroon.button--inverted:hover {
        background-color: var(--color-maroon);
        border-color: var(--color-maroon);
        color: white;
        color: var(--color-white); }
  .button--orange {
    background-color: #f1672a;
    background-color: var(--color-orange);
    border-color: #f1672a;
    border-color: var(--color-orange);
    color: white;
    color: var(--color-white); }
    .button--orange:hover {
      border-color: #f1672a;
      border-color: var(--color-orange); }
    .button--orange.button--inverted {
      background-color: transparent;
      border-color: #f1672a;
      border-color: var(--color-orange);
      color: #f1672a;
      color: var(--color-orange); }
      .button--orange.button--inverted:hover {
        background-color: #f1672a;
        background-color: var(--color-orange);
        border-color: #f1672a;
        border-color: var(--color-orange);
        color: white;
        color: var(--color-white); }
  .button--green {
    background-color: var(--color-green);
    border-color: var(--color-green);
    color: white;
    color: var(--color-white); }
    .button--green:hover {
      border-color: var(--color-green); }
    .button--green.button--inverted {
      background-color: transparent;
      border-color: var(--color-green);
      color: var(--color-green); }
      .button--green.button--inverted:hover {
        background-color: var(--color-green);
        border-color: var(--color-green);
        color: white;
        color: var(--color-white); }
  .button--type {
    background-color: #20221D;
    background-color: var(--color-type);
    border-color: #20221D;
    border-color: var(--color-type);
    color: white;
    color: var(--color-white); }
    .button--type:hover {
      border-color: #20221D;
      border-color: var(--color-type); }
    .button--type.button--inverted {
      background-color: transparent;
      border-color: #20221D;
      border-color: var(--color-type);
      color: #20221D;
      color: var(--color-type); }
      .button--type.button--inverted:hover {
        background-color: #20221D;
        background-color: var(--color-type);
        border-color: #20221D;
        border-color: var(--color-type);
        color: white;
        color: var(--color-white); }

.link-button {
  color: var(--color-island);
  text-decoration: underline; }

body.menu-open #root {
  overflow: hidden; }

canvas {
  position: fixed; }

section {
  position: relative;
  /*@include breakpoint('md') {
        min-height: 100vh;
    }*/ }

#root {
  position: relative;
  z-index: 1; }

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 90rem;
  padding: 0 1.25rem;
  padding: 0 var(--grid-gutter); }
  .container--flex {
    display: flex; }
  .container--full {
    max-width: 100%;
    padding: 0; }

.section--padding {
  padding: 5rem 0;
  padding: var(--quadriple-padding) 0; }

.section-header {
  margin-bottom: 1.25rem;
  margin-bottom: var(--base-padding); }
  @media (min-width: 768px) {
    .section-header {
      margin-bottom: 2.5rem;
      margin-bottom: var(--double-padding); } }

.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22rem;
  margin-bottom: 1rem;
  padding: 1.25rem;
  padding: var(--base-padding); }

.section-info {
  margin-top: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .section-info {
      padding-left: 2rem; } }

.section-title {
  font-size: 1.5rem; }
  @media (min-width: 768px) {
    .section-title {
      font-size: 3rem; } }

.intro .container--flex {
  flex-direction: column; }
  @media (min-width: 768px) {
    .intro .container--flex {
      flex-direction: row; } }

.content {
  display: flex; }

.content-box {
  padding-left: 1.25rem;
  padding-left: var(--base-padding);
  padding-right: 1.25rem;
  padding-right: var(--base-padding); }
  @media (min-width: 768px) {
    .content-box {
      padding-left: 2.5rem;
      padding-left: var(--double-padding);
      padding-right: 2.5rem;
      padding-right: var(--double-padding); } }

.image-contain {
  object-fit: contain; }

.intro__content {
  position: relative;
  z-index: 1;
  align-self: center;
  background-color: white;
  padding: 1.25rem;
  padding: var(--base-padding);
  font-size: .875rem; }
  @media (min-width: 768px) {
    .intro__content {
      padding: 2.5rem 3.75rem 2.5rem 2.5rem;
      padding: var(--double-padding) var(--triple-padding) var(--double-padding) var(--double-padding);
      margin-right: -2.5rem;
      font-size: 1.125rem;
      padding: 2.5rem;
      padding: var(--double-padding);
      padding-right: 5rem;
      padding-right: var(--quadriple-padding); } }
  .intro__content--dark {
    width: 100%;
    background-color: #eee; }

.intro__image {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem; }
  @media (min-width: 768px) {
    .intro__image {
      margin: 0; } }

.intro__video {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem; }
  .intro__video video {
    object-fit: contain; }
  @media (min-width: 768px) {
    .intro__video {
      margin: 0; } }

.intro__software {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  padding: var(--double-padding);
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  margin: 0 -1.25rem;
  background: white; }
  .intro__software li {
    padding: 3px 0;
    list-style: none; }
  .intro__software img {
    height: 16px;
    width: 16px;
    float: left;
    display: block;
    margin: 1px 5px; }

.half {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%; }

.software {
  display: flex; }

.streaming {
  position: relative; }
  .streaming .container--flex {
    flex-direction: column; }
    @media (min-width: 768px) {
      .streaming .container--flex {
        flex-direction: row; } }

.eight__bit {
  position: absolute;
  z-index: 1;
  top: -67px;
  width: 4rem; }

.numbers {
  color: white; }

.latest--home .website__summary {
  display: flex;
  font-size: 1.875rem !important;
  font-family: 'Exo', sans-serif;
  line-height: 2.5rem; }

.latest--home .website__name {
  font-size: 1.125rem; }

.latest--home .website__content {
  display: flex !important;
  max-width: 46%;
  top: 1.25rem;
  top: var(--base-padding); }

.latest--home .card--website::before {
  opacity: .2; }

.latest--home .section-header {
  color: white; }

.grid {
  display: flex;
  display: grid;
  grid-gap: 1rem; }

.no-cssgrid .grid {
  flex-direction: row;
  flex-wrap: wrap; }

.grid--two {
  grid-template-columns: 1fr 1fr; }

.no-cssgrid .grid--two {
  flex-direction: row;
  flex-wrap: wrap; }
  .no-cssgrid .grid--two .grid-item {
    width: calc(50% - 1rem);
    margin: .5rem; }

.grid--three {
  grid-template-columns: 1fr; }

.no-cssgrid .grid--three .grid-item {
  width: calc(33.33% - 1rem);
  margin: 0.5rem; }

.grid--four {
  grid-template-columns: 1fr; }

.no-cssgrid .grid--four .grid-item {
  width: calc(25% - 1rem);
  margin: 0.5rem; }

.grid--five {
  grid-template-columns: 1fr; }

.no-cssgrid .grid--five .grid-item {
  width: calc(20% - 1rem);
  margin: 0.5rem; }

.grid--featured {
  grid-template-columns: 1fr;
  grid-gap: 0;
  background-color: #101110; }
  @media (min-width: 768px) {
    .grid--featured {
      grid-template-columns: 1fr 1fr; } }
  .grid--featured div:not(.grid-intro) {
    margin: 1.25rem;
    margin: var(--base-padding); }
    @media (min-width: 768px) {
      .grid--featured div:not(.grid-intro) {
        margin: 0; } }
    .grid--featured div:not(.grid-intro):nth-child(2) {
      margin-bottom: 0; }

.grid--clients {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem; }
  @media (min-width: 768px) {
    .grid--clients {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 5rem; } }

.grid--awards {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem; }
  @media (min-width: 768px) {
    .grid--awards {
      grid-template-columns: repeat(6, 1fr); } }

.grid--recommendations {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3rem; }
  @media (min-width: 768px) {
    .grid--recommendations {
      grid-template-columns: repeat(3, 1fr); } }

.grid--websites {
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem; }
  @media (min-width: 768px) {
    .grid--websites {
      grid-template-columns: repeat(4, 1fr); } }

.grid--services {
  grid-template-columns: repeat(2, 1fr);
  width: 50%; }

.grid-intro {
  grid-row: 1; }
  @media (min-width: 768px) {
    .grid-intro {
      grid-row: 1/3; } }

.grid--certifications {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) {
    .grid--certifications {
      grid-gap: 4rem; } }

.works {
  display: none; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

html {
  width: 100%;
  box-sizing: border-box;
  font-family: 'Rajdhani',sans-serif;
  font-family: var(--font-base);
  font-weight: 400;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0;
  font-family: 'Rajdhani',sans-serif;
  font-family: var(--font-base); }
  *, *::before, *::after {
    box-sizing: inherit; }

body {
  color: #20221D;
  color: var(--font-color);
  font-family: 'Rajdhani',sans-serif;
  font-family: var(--font-base);
  font-size: 1rem;
  overflow-wrap: break-word;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #eee; }
  @media (min-width: 768px) {
    body {
      font-size: 1.25rem; } }

hr {
  height: 0;
  overflow: visible; }

nav ol,
nav ul {
  list-style: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    text-decoration: underline;
    cursor: pointer; }

b,
strong {
  font-weight: bold; }

::-moz-selection {
  background-color: var(--selection-color);
  color: #20221D;
  color: var(--font-color);
  text-shadow: none; }

::selection,
::-moz-selection {
  background-color: var(--selection-color);
  color: #20221D;
  color: var(--font-color);
  text-shadow: none; }

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  border-style: none; }

button,
input,
select,
textarea {
  color: inherit;
  font: inherit; }

button,
input,
select {
  overflow: visible; }

button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

input {
  overflow: visible; }

textarea {
  resize: vertical;
  overflow: auto; }

select {
  text-transform: none; }

::-moz-focus-inner {
  border-style: none;
  padding: 0; }

:focus {
  outline: var(--color-cobalt) auto 1px; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption,
th,
td {
  text-align: left;
  font-weight: normal; }

audio,
canvas,
iframe,
svg,
video {
  vertical-align: middle; }

audio:not([controls]) {
  display: none;
  height: 0; }

svg:not([fill]) {
  fill: currentColor; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation; }

[aria-busy='true'] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled='true'],
[disabled] {
  cursor: not-allowed; }

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

video {
  object-fit: cover;
  height: 100%;
  width: 100%; }

details {
  margin-bottom: 1rem; }

.background--white {
  background-color: white;
  background-color: var(--color-white); }

.background--darkgrey {
  background-color: #20221D;
  background-color: var(--color-darkgrey); }

.background--island {
  background-color: var(--color-island); }

.background--cobalt {
  background-color: var(--color-cobalt); }

.background--zinc {
  background-color: var(--color-zinc); }

.background--alloy {
  background-color: var(--color-alloy); }

.background--pigeon {
  background-color: var(--color-pigeon); }

.background--cadet {
  background-color: var(--color-cadet); }

.background--red {
  background-color: var(--color-red); }

.background--maroon {
  background-color: var(--color-maroon); }

.background--orange {
  background-color: #f1672a;
  background-color: var(--color-orange); }

.background--green {
  background-color: var(--color-green); }

.background--type {
  background-color: #20221D;
  background-color: var(--color-type); }

.background--skew-left {
  background: linear-gradient(135deg, #20221d 0%, #20221d 50%, #eee 50%, #eee 100%); }

.background--skew-right {
  background: linear-gradient(135deg, #fff 0%, #fff 50%, #20221d 50%, #20221d 100%); }

.background--image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .background--image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 1.25rem;
  margin-bottom: var(--base-padding);
  line-height: 1.5; }

h1 {
  font-size: 1.25rem; }

h2 {
  font-size: 1.125rem; }

h3 {
  font-size: 1; }

h4,
h5,
h6 {
  font-size: 1.25rem; }

a {
  color: var(--color-island); }

ul.bullet-list {
  margin-left: 2.5rem;
  margin-left: var(--double-padding);
  margin-bottom: 0; }
  @media (min-width: 768px) {
    ul.bullet-list {
      margin-bottom: 1.25rem;
      margin-bottom: var(--base-padding); } }
  ul.bullet-list li {
    padding-bottom: 0.625rem;
    padding-bottom: var(--half-padding); }

ul.stat-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none; }
  @media (min-width: 768px) {
    ul.stat-list {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  ul.stat-list li {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.25rem;
    padding: var(--base-padding);
    text-align: center;
    font-size: 1rem; }
    @media (min-width: 768px) {
      ul.stat-list li {
        margin-right: 1.25rem;
        margin-right: var(--base-padding);
        font-size: 20px; } }
  ul.stat-list span {
    color: #f1672a;
    margin-right: .5rem;
    font-size: 2rem;
    font-weight: 900;
    font-family: 'Exo', sans-serif; }
    @media (min-width: 768px) {
      ul.stat-list span {
        font-size: 7rem; } }

.center-text {
  text-align: center; }

.section-title {
  font-family: 'Exo', sans-serif;
  font-weight: 600; }

.filters {
  display: none;
  list-style: none; }
  .filters a {
    text-decoration: none; }

.filter {
  position: relative;
  min-width: auto;
  width: 100%; }
  @media (min-width: 768px) {
    .filter {
      position: absolute;
      right: 1.25rem;
      right: var(--base-padding);
      bottom: 1.25rem;
      bottom: var(--base-padding);
      min-width: 20rem;
      width: auto; } }
  .filter input {
    width: 100%;
    padding: 1rem; }

.logo {
  background: #3d3d3d;
  margin: 1rem;
  padding: 1rem;
  width: 10rem; }
  @media (min-width: 768px) {
    .logo {
      width: 14rem; } }
  .logo path {
    -webkit-transition: fill 1s ease;
    transition: fill 1s ease; }

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: grayscale(1);
          filter: grayscale(1); }
  .card--large {
    grid-column-end: span 2; }
  .card--recommendation {
    position: relative;
    flex-direction: column;
    justify-content: start;
    background-color: white;
    padding: 1.25rem;
    padding: var(--base-padding);
    border-radius: 0 0 0 4rem;
    -webkit-filter: none;
            filter: none; }
    .card--recommendation .card__profile {
      display: flex;
      width: 100%;
      margin-top: auto; }
    .card--recommendation .card__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'Exo', sans-serif; }
    .card--recommendation .card__name {
      font-weight: 600;
      margin-bottom: .5rem; }
    .card--recommendation .card__title, .card--recommendation .card__company {
      font-size: 0.875rem; }
      @media (min-width: 768px) {
        .card--recommendation .card__title, .card--recommendation .card__company {
          font-size: 1rem; } }
    .card--recommendation .card__image {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      margin-right: 1.25rem;
      margin-right: var(--base-padding);
      overflow: hidden; }
      .card--recommendation .card__image img {
        width: 5rem;
        height: 5rem;
        object-fit: cover; }
    .card--recommendation .card__recommendation {
      font-style: italic;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;
      margin-bottom: var(--base-padding); }
      @media (min-width: 768px) {
        .card--recommendation .card__recommendation {
          font-size: 1.125rem; } }
  .card--website {
    -webkit-filter: none;
            filter: none;
    position: relative;
    height: 15rem;
    justify-content: start;
    align-items: flex-start;
    overflow: hidden; }
    @media (min-width: 768px) {
      .card--website {
        height: 24rem; } }
    .card--website:hover::before {
      opacity: .5;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease; }
    .card--website:hover .website__content {
      display: flex;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .card--website:hover img {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: -webkit-transform 4s ease-out 0s;
      transition: -webkit-transform 4s ease-out 0s;
      transition: transform 4s ease-out 0s;
      transition: transform 4s ease-out 0s, -webkit-transform 4s ease-out 0s; }
    .card--website::before {
      content: "";
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease; }
    .card--website img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute; }
    .card--website .website__content {
      display: none;
      flex-direction: column;
      padding: 1rem;
      position: absolute;
      z-index: 1;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
      @media (min-width: 768px) {
        .card--website .website__content {
          padding: 2.5rem;
          padding: var(--double-padding);
          top: 1.25rem;
          top: var(--base-padding);
          left: 1.25rem;
          left: var(--base-padding); } }
    .card--website .website__name {
      font-size: 1rem;
      color: white;
      margin-bottom: 1.25rem;
      margin-bottom: var(--base-padding);
      background-color: #3d3d3d;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: .5rem;
      border-radius: 0 0 0 10px; }
    .card--website .website__summary {
      display: none;
      font-size: 2rem;
      color: white; }
      @media (min-width: 768px) {
        .card--website .website__summary {
          display: block; } }
    .card--website .website__tag {
      position: absolute;
      z-index: 1;
      bottom: .75rem;
      right: .75rem;
      border: 1px solid white;
      border-radius: 50%;
      padding: .5rem; }
      @media (min-width: 768px) {
        .card--website .website__tag {
          bottom: 1.25rem;
          bottom: var(--base-padding);
          right: 1.25rem;
          right: var(--base-padding); } }
      .card--website .website__tag svg {
        fill: #fff; }
    .card--website .website__url {
      position: absolute;
      bottom: .75rem;
      right: 3.5rem;
      padding: .5rem; }
      @media (min-width: 768px) {
        .card--website .website__url {
          bottom: 1.25rem;
          bottom: var(--base-padding);
          right: 4rem; } }
  .card--service {
    -webkit-filter: none;
            filter: none;
    flex-direction: column;
    background-color: white;
    padding: 1.25rem;
    padding: var(--base-padding); }
    .card--service .card__icon {
      height: 10rem;
      width: 10rem; }
      .card--service .card__icon img {
        width: 100%;
        height: 100%; }
    .card--service .card__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'Exo', sans-serif; }
    .card--service .card__name {
      margin-bottom: 1.25rem;
      margin-bottom: var(--base-padding); }
    .card--service .card__summary {
      font-size: .875rem; }

.certificate {
  display: flex;
  flex-direction: column; }
  .certificate img {
    height: 4rem;
    width: 10rem;
    margin-bottom: 1rem;
    margin-right: auto; }
  .certificate p {
    margin-bottom: 0; }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      -webkit-user-select: none;
              user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .glide--rtl {
    direction: rtl; }

