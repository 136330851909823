// ==========================================================================
//   Container.
// ==========================================================================

body.menu-open #root {
    overflow: hidden;
}



canvas {
    position: fixed;
}

section {
    position: relative;

    /*@include breakpoint('md') {
        min-height: 100vh;
    }*/
}

#root {
    position: relative;
    z-index: 1;
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 90rem;
    padding: 0 var(--grid-gutter);

    &--flex {
        display: flex;
    }

    &--full {
        max-width: 100%;
        padding: 0;
    }
}

.section--padding {
    padding: var(--quadriple-padding) 0;
}

.section-header {
    margin-bottom: var(--base-padding);

    @include breakpoint('md') {
        margin-bottom: var(--double-padding);
    }
}

.page-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 22rem;
    margin-bottom: 1rem;
    padding: var(--base-padding);
}

.section-info {
    margin-top: auto;
    margin-right: auto;

    @include breakpoint('md') {
        padding-left: 2rem;
    }
}

.section-title {
    font-size: 1.5rem;

    @include breakpoint('md') {
        font-size: 3rem;
    }
}

.intro {
    .container--flex {
        flex-direction: column;

        @include breakpoint('md') {
            flex-direction: row;
        }
    }
}

.content {
    display: flex;
}

.content-box {
    padding-left: var(--base-padding);
    padding-right: var(--base-padding);

    @include breakpoint('md') {
        padding-left: var(--double-padding);
        padding-right: var(--double-padding);
    }
}

.image-contain {
    object-fit: contain;
}

.intro__content {
    position: relative;
    z-index: 1;
    align-self: center;
    background-color: white;
    padding: var(--base-padding);
    font-size: .875rem;

    @include breakpoint('md') {
        padding: var(--double-padding) var(--triple-padding) var(--double-padding) var(--double-padding);
        margin-right: -2.5rem;
        font-size: 1.125rem;
        padding: var(--double-padding);
        padding-right: var(--quadriple-padding);
    }

    &--dark {
        width: 100%;
        background-color: #eee;
    }
}

.intro__image {
    display: flex;
    position: relative;
    z-index: 1;
    flex: 1 0 50%;
    margin: 0 -1.25rem;

    @include breakpoint('md') {
        margin: 0;
    }
}

.intro__video {
    display: flex;
    position: relative;
    z-index: 1;
    flex: 1 0 50%;
    margin: 0 -1.25rem;

    video {
        object-fit: contain;
    }

    @include breakpoint('md') {
        margin: 0;
    }
}

.intro__software {
    display: flex;
    flex-direction: column;
    padding: var(--double-padding);
    position: relative;
    z-index: 1;
    flex: 1 0 50%;
    margin: 0 -1.25rem;
    background: white;

    li {
        padding: 3px 0;
        list-style: none;
    }

    img {
        height: 16px;
        width: 16px;
        float: left;
        display: block;
        margin: 1px 5px;
    }
}

.half {
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
}

.software {
    display: flex;
}

.streaming {
    position: relative;

    .container--flex {
        flex-direction: column;

        @include breakpoint('md') {
            flex-direction: row;
        }
    }
}

.eight__bit {
    position: absolute;
    z-index: 1;
    top: -67px;
    width: 4rem;
}

.numbers {
    color: white;
}

.latest--home .website__summary {
    display: flex;
    font-size: 1.875rem !important;
    font-family: 'Exo', sans-serif;
    line-height: 2.5rem;
}

.latest--home .website__name {
    font-size: 1.125rem;
}

.latest--home .website__content {
    display: flex !important;
    max-width: 46%;
    top: var(--base-padding);
}

.latest--home .card--website::before {
    opacity: .2;
}

.latest--home .section-header {
    color: white;
}