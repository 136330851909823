.logo {
    background: #3d3d3d;
    margin: 1rem;
    padding: 1rem;
    width: 10rem;
    
    @include breakpoint('md') {
      width: 14rem;
    }
    
    path {
      transition: fill 1s ease;
    }

    
  }